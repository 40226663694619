import { getEnv } from "app/env";

const apiRoot = getEnv("API_ROOT");

const testersApiRoot = `${apiRoot}/api/testers`;

export const testerApiPaths = {
  graphql: `${testersApiRoot}/graphql`,
  password: `${testersApiRoot}/password`,
  signIn: `${testersApiRoot}/sign_in`,
  signUp: `${testersApiRoot}/sign_up`,
  signOut: `${testersApiRoot}/sign_out`,
  loginTokenSignIn: `${testersApiRoot}/auth_with_login_token`,
  tracker_sso: `${testersApiRoot}/tracker_sso`,
  registrationConfirmation: `${testersApiRoot}/registration_confirmation`,
  checkUpVerification: `${testersApiRoot}/check_up_verification`,
  consentsList: `${testersApiRoot}/consents`,
} as const;
