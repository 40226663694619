/* eslint-disable no-use-before-define, import/order */
import "core-js/stable";
import "regenerator-runtime/runtime";
import Sentry from "app/sentry";

Sentry.install();

import "@hopper/styles/dist/index.css";
import "assets/stylesheets/bootstrap.scss";
import "assets/stylesheets/bootstrap_override.scss";
import "assets/stylesheets/font-awesome.min.scss";
import "assets/stylesheets/react-toastify.scss";
import "sweetalert/dist/sweetalert.css";
import "assets/stylesheets/wizard_steps.scss";
import "assets/stylesheets/styles/main.scss";

import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
window.EventSource = NativeEventSource || EventSourcePolyfill;

import "isomorphic-fetch";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "store/configure_store";

import SentryExceptionBoundary from "app/sentry/react_exception_boundary";
import "./helpers/yup_custom_validations";
import Root from "./root";

const store = configureStore();

const Loader = () => "Loading...";

const Main = () => (
  <SentryExceptionBoundary>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <Root />
      </Suspense>
    </Provider>
  </SentryExceptionBoundary>
);

const renderAndMount = () => {
  ReactDOM.render(<Main />, document.getElementById("app"));
};

document.addEventListener("DOMContentLoaded", () => {
  renderAndMount();
});

export default Main;
/* eslint-enable no-use-before-define, import/order */
