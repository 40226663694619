import { hot } from "react-hot-loader/root";
import React, { useEffect, useRef } from "react";
import { Redirect, BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import { websiteContentPaths, defaultPagePath } from "configuration/app_paths";
// @ts-expect-error: TS2307
import Analytics from "app/analytics";
// @ts-expect-error: TS2307
import InternetExplorerCheck from "app/internet_explorer_check";
// @ts-expect-error: TS2307
import { AuthenticatedApolloProvider } from "./app/apollo_client";

const UpworkProxy = React.lazy(() => import("components/upwork_proxy"));
const ReproducibilityTests = React.lazy(() => import("components/reproducibility_tests"));
const TestCycle = React.lazy(() => import("components/test_cycle"));
const FlexibleTesting = React.lazy(() => import("components/flexible_testing"));
const TesterAccount = React.lazy(() => import("components/tester_account"));

type AutoScrollTopProps = { children: React.ReactNode };

const AutoScrollTop = ({ children }: AutoScrollTopProps) => {
  const location = useLocation();
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
};

const Root = () => (
  <Router>
    <CompatRouter>
      <AuthenticatedApolloProvider>
        <AutoScrollTop>
          <div className="root tw">
            <Analytics>
              <InternetExplorerCheck>
                <Switch>
                  <Route path={websiteContentPaths.flexibleTestingRoot} component={FlexibleTesting} />
                  <Route path={websiteContentPaths.reproducibilityTestsRoot} component={ReproducibilityTests} />
                  <Route path={websiteContentPaths.testerAccountRoot} component={TesterAccount} />
                  <Route path={websiteContentPaths.upwork} component={UpworkProxy} />
                  <Route path={websiteContentPaths.testCaseInvitations} component={TestCycle} />
                  <Route path="*" render={props => <Redirect to={`${defaultPagePath}${props.location.search}`} />} />
                </Switch>
              </InternetExplorerCheck>
            </Analytics>
          </div>
        </AutoScrollTop>
      </AuthenticatedApolloProvider>
    </CompatRouter>
  </Router>
);

export default hot(Root);
