export function reduceReducers(...reducers) {
  if (reducers.length === 0) {
    throw new Error(
      "You called reduceReducers without any params. You need to provide some reducers so we can combine them.",
    );
  }

  const reducer = function (initialState = {}, action) {
    var internalReducer = function (previousState, currentReducer, index, array) {
      return currentReducer(previousState, action);
    };

    var result = reducers.reduce(internalReducer, initialState);

    return result;
  };

  return reducer;
}

export function createReducer(requiredActionType, reducerContentCallback) {
  if (!requiredActionType) {
    throw new Error("Missing required action type");
  }

  return (previousState, action) => {
    if (requiredActionType === action.type) {
      return reducerContentCallback(previousState, action);
    } else {
      return previousState;
    }
  };
}

export function createCombinedReducerProxy(reducer, fragmentName) {
  return (state, action) => {
    const newState = reducer({ [fragmentName]: state }, action);

    return newState ? newState[fragmentName] || null : null;
  };
}
