import { omit, evolve, mergeRight, __ } from "ramda";
import uniqueId from "lodash/uniqueId";

import { createReducer, reduceReducers } from "../helpers";
import types from "../../action_types/global_action_types";

const reducers = [
  createReducer(types.ADD_ALERT, (previousState, action) => {
    let newState = previousState;
    const alertKey = uniqueId("alert");
    const transformations = {
      alerts: mergeRight(__, {
        [alertKey]: {
          type: action.payload.type,
          message: action.payload.message,
          delay: action.payload.delay,
        },
      }),
    };

    newState = evolve(transformations, newState);

    return newState;
  }),

  createReducer(types.REMOVE_ALERT, (previousState, action) => {
    let newState = previousState;

    const transformations = {
      alerts: omit([action.payload.alertKey], __),
    };

    newState = evolve(transformations, newState);

    return newState;
  }),

  createReducer(types.CLEAN_ALERTS, (previousState, _action) => ({ ...previousState, alerts: {} })),
];

export default reduceReducers(...reducers);
