import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise";
import { compact } from "lodash";

import apiMiddleware from "middleware/api";
import initialState from "./initial_state";
import rootReducer from "../reducers/root_reducer";
import createLoginMiddleware from "../middleware/tester_login";
import featureFlags from "../app/feature_flags";
import Sentry from "app/sentry";

const loginMiddleware = createLoginMiddleware(() => {
  featureFlags.shutdown();
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = compact([
  thunkMiddleware,
  promiseMiddleware,
  apiMiddleware,
  loginMiddleware,
  Sentry.reduxMiddleware,
]);

const storeMiddleware = composeEnhancers(applyMiddleware(...middleware));

export default function configureStore() {
  const store = createStore(rootReducer, initialState, storeMiddleware);

  return store;
}
