import { createActionTypes } from "../helpers";

const types = createActionTypes("testerAccount/notifications", [
  "SET_INITIAL_STATE",
  "DISMISS_SETUP",
  "BLOCK_ACCESS",
  "GRANT_ACCESS",
  "DISMISSAL_EXPIRED",
]);

export default types;
