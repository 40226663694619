import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Heading } from "@hopper/heading";

const ErrorModal = ({ isOpen, onClose }) => (
  <Modal show={isOpen}>
    <div className="z-50 m-sm absolute top-0 right-0">
      {/* eslint-disable-next-line react/forbid-elements */}
      <button onClick={onClose}>
        <i className="fa fa-close fa-2x" />
      </button>
    </div>
    <Modal.Body>
      <Heading size={3}>It looks like you’re using Internet Explorer</Heading>
      <p className="mb-md">IE is not fully supported. Some parts of Tester Work may not work as expected.</p>

      <p className="mb-md">
        We strongly recommend opening Tester Work on one of our supported browsers (Chrome, Firefox, or Safari) for the
        best experience.
      </p>

      <p>If the test requires you to open the test URL on an unsupported browser, you can still do that.</p>
    </Modal.Body>
  </Modal>
);

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModal;
