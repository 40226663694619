import { createReducer } from "../helpers";
import types from "../../action_types/tester_account/notifications_action_types";

const reducers = [
  createReducer(types.SET_INITIAL_STATE, (previousState, { initialState }) => ({
    ...previousState,
    testerAccount: {
      ...previousState.testerAccount,
      notifications: initialState,
    },
  })),
  createReducer(types.DISMISS_SETUP, (previousState, { displayNextPromptAfter }) => ({
    ...previousState,
    testerAccount: {
      ...previousState.testerAccount,
      notifications: {
        ...previousState.testerAccount.notifications,
        setup: "dismissed",
        displayNextPromptAfter,
      },
    },
  })),
  createReducer(types.BLOCK_ACCESS, previousState => ({
    ...previousState,
    testerAccount: {
      ...previousState.testerAccount,
      notifications: {
        ...previousState.testerAccount.notifications,
        setup: "blocked",
      },
    },
  })),
  createReducer(types.GRANT_ACCESS, previousState => ({
    ...previousState,
    testerAccount: {
      ...previousState.testerAccount,
      notifications: {
        ...previousState.testerAccount.notifications,
        setup: "completed",
      },
    },
  })),
  createReducer(types.DISMISSAL_EXPIRED, previousState => ({
    ...previousState,
    testerAccount: {
      ...previousState.testerAccount,
      notifications: {
        ...previousState.testerAccount.notifications,
        setup: "prompt",
        displayNextPromptAfter: null,
      },
    },
  })),
];

export default reducers;
