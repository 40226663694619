import { Notification, NotificationProps } from "@hopper/notification";
import { toast } from "react-toastify";
import React from "react";

const useNotification =
  () =>
  (
    title: NotificationProps["title"],
    message: NotificationProps["subtitle"] | null,
    variant: NotificationProps["variant"],
    options: Record<string, unknown> = {},
  ) => {
    const toastId = `${title}-${message}`;
    const defaultToastOptions = { toastId, closeButton: false };
    const toastOptions = { ...defaultToastOptions, ...options };

    return toast(
      <div className="bg-lightest mb-1" data-testid="notification-toast">
        <Notification
          onClose={() => toast.dismiss(toastId)}
          title={title}
          subtitle={message || undefined}
          variant={variant}
        />
      </div>,
      toastOptions,
    );
  };

export default useNotification;
