import { FetchPolicy, useQuery } from "@apollo/client";

import { GetCurrentTesterDocument } from "generated/graphql";

const useCurrentTester = (config?: { fetchPolicy?: FetchPolicy }) => {
  const { data, loading, refetch } = useQuery(GetCurrentTesterDocument, config);

  return {
    loading,
    currentTester: data?.tester,
    refetch,
  };
};

export default useCurrentTester;
