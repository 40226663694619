import { combineReducers } from "redux";

import { createCombinedReducerProxy } from "./helpers";
import alertsReducers from "./alerts";
import testerAccountReducer from "./tester_account";

export default combineReducers({
  testerAccount: createCombinedReducerProxy(testerAccountReducer, "testerAccount"),
  alerts: createCombinedReducerProxy(alertsReducers, "alerts"),
});
