import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { connect } from "react-redux";

import GraphQLErrors from "components/graphql_errors";
import { client, setToken } from "./client";

const mapStateToProps = ({ testerAccount }) => ({
  authenticationToken: testerAccount.authenticationToken,
});

const AuthenticatedApolloProvider = connect(mapStateToProps)(({ authenticationToken, children }) => {
  useEffect(() => setToken(authenticationToken), [authenticationToken]);

  return (
    <ApolloProvider client={client}>
      <GraphQLErrors />
      {children}
    </ApolloProvider>
  );
});

export default AuthenticatedApolloProvider;
