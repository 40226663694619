import { always, evolve } from "ramda";

import { catchStorageError } from "generic_helpers";
import { createReducer } from "../helpers";
import types from "action_types/tester_account/authentication_action_types";
import initialStore from "store/initial_state";

const reducers = [
  createReducer(types.SIGN_IN, (previousState, { payload: { authenticationToken, rememberMe } }) => {
    let newState = previousState;

    const transformations = {
      testerAccount: {
        authenticationToken: always(authenticationToken),
      },
    };

    newState = evolve(transformations, newState);

    if (rememberMe) {
      catchStorageError(() => {
        localStorage.setItem("testerAccountAuthenticationToken", newState.testerAccount.authenticationToken);
      });
    } else {
      catchStorageError(() => {
        sessionStorage.setItem("testerAccountAuthenticationToken", newState.testerAccount.authenticationToken);
      });
    }

    return newState;
  }),

  createReducer(types.AUTO_SIGN_IN, previousState => {
    let newState = previousState;
    const sessionAuthenticationToken = sessionStorage.getItem("testerAccountAuthenticationToken");
    const localAuthenticationToken = localStorage.getItem("testerAccountAuthenticationToken");
    const authenticationToken = sessionAuthenticationToken || localAuthenticationToken;

    let transformations = {};

    if (authenticationToken) {
      transformations = {
        testerAccount: {
          authenticationToken: always(authenticationToken),
          autoSignedIn: always(true),
        },
      };
    } else {
      transformations = {
        testerAccount: {
          autoSignedIn: always(true),
        },
      };
    }

    newState = evolve(transformations, newState);

    return newState;
  }),

  createReducer(types.SIGN_OUT, previousState => {
    let newState = previousState;

    const transformations = {
      testerAccount: always(initialStore.testerAccount),
    };

    newState = evolve(transformations, newState);

    catchStorageError(() => {
      sessionStorage.removeItem("testerAccountAuthenticationToken");
      localStorage.removeItem("testerAccountAuthenticationToken");
    });

    return newState;
  }),

  createReducer(types.LOGIN_TOKEN_CHECKED, previousState => {
    let newState = previousState;

    const transformations = {
      testerAccount: {
        loginTokenChecked: always(true),
      },
    };

    newState = evolve(transformations, newState);

    return newState;
  }),
];

export default reducers;
