import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

import useNotification from "components/shared/helpers/use_notification";
import { testerAccountPaths } from "configuration/app_paths";
import GET_ERROR from "./get_error";

const ERROR_CODES_TO_IGNORE = [
  "TEST_IS_NO_LONGER_AVAILABLE",
  403,
  "REMOVED_FROM_TEST",
  "PARTICIPANT_REMOVED_EXPIRED_INVITE",
  "TEST_HAS_NO_AVAILABLE_SEATS",
  "TESTER_IS_MODERATOR",
];

const default_error_message = "Something went wrong. Please try again.";

const getMessage = (code: number | string, originalMessage: string) =>
  ({
    403: "You're unauthorized to view this content or perform this action. Please refresh the page or try again.",
    404: "Requested resource doesn't exist.",
    500: "We've been notified about the issue.",
    NETWORK_ERROR: "It looks like a problem with the internet connection.",
    CHECK_UP_VERIFICATION_FAILED: originalMessage,
  })[code] || default_error_message;

const GraphQLErrors = () => {
  const { data, client } = useQuery<{ error: { code: number | string; message: string } }>(GET_ERROR);
  const location = useLocation();
  const history = useHistory();

  const { code: errorCode, message: originalMessage } =
    data && data.error ? data.error : { code: undefined, message: undefined };
  const message = errorCode ? getMessage(errorCode, originalMessage) : "";
  const notify = useNotification();

  const dismissError = () => {
    client.writeQuery({
      query: GET_ERROR,
      data: {
        error: null,
      },
    });
  };

  // We only want to redirect ONCE per errorCode.
  // Due to async nature of dismissError, this might not be the case unless
  // we deduplicate on errorCode
  useEffect(() => {
    if (errorCode === 401) history.push(testerAccountPaths.signOut);

    if (errorCode === "CHECK_UP_VERIFICATION_FAILED") {
      const params = queryString.stringify({ url: location.pathname });

      history.push(`${testerAccountPaths.checkUpVerification}?${params}`);
    }
  }, [errorCode]);

  if (errorCode && ERROR_CODES_TO_IGNORE.includes(errorCode)) {
    return null;
  }

  if (errorCode === 401) {
    dismissError();
  } else if (errorCode === "CHECK_UP_VERIFICATION_FAILED") {
    notify(message, "", "info");

    dismissError();
  } else if (!!message) {
    notify("Something went wrong", message, "danger");

    dismissError();
  }

  return null;
};

export default GraphQLErrors;
