import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useLocation, matchPath } from "react-router-dom";
import track from "react-tracking";

import useCurrentTester from "components/shared/current_tester/use_current_tester";
import config from "./config";
import injectSegment from "./segment_snippet";
// Segment includes whole lodash library, load it async

const CookieConsent = React.lazy(() => import("./cookie_consent"));

// In guard to avoid publishing events without Tester identity
let testerIdentified = false;

class Analytics {
  static eventsQueue = [];

  static processEvent = event => {
    if (event.action === "track") {
      if (window.analytics) {
        window.analytics.track(event.name, event.properties);
      }
    } else {
      throw new Error("Unknown event action: " + event.action);
    }
  };

  static identify(id, email, rank) {
    if (window.analytics) {
      window.analytics.identify(id, { email, rank });
    }
  }

  static page() {
    if (window.analytics) {
      window.analytics.page();
    }
  }

  static addEvent(event) {
    Analytics.eventsQueue.push(event);
  }

  static reset() {
    if (window.analytics) {
      window.analytics.reset();
    }
  }

  static processEvents() {
    const event = Analytics.eventsQueue.shift();

    if (event) {
      Analytics.processEvent(event);
      Analytics.processEvents();
    }
  }
}

const AnalyticsTracker = ({ children }) => {
  const { currentTester } = useCurrentTester({ fetchPolicy: "cache-only" });
  const { pathname } = useLocation();
  const previousTester = useRef();
  const previousPathname = useRef();

  useEffect(() => {
    if (config.segmentWriteKey) {
      injectSegment();
    }

    return () => {
      testerIdentified = false;
      Analytics.reset();
    };
  }, []);

  useEffect(() => {
    identifyTester()
      .then(
        () => {
          trackPage();
          testerIdentified = true;
          Analytics.processEvents();
        },
        () => {
          if (testerIdentified) {
            testerIdentified = false;
            Analytics.reset();
          }
        },
      )
      .finally(() => {
        previousTester.current = currentTester;
        previousPathname.current = pathname;
      });
  }, [currentTester, pathname]);

  const identifyTester = () =>
    new Promise((resolve, reject) => {
      const { publicId: oldPublicId } = previousTester.current || {};
      const { publicId, email, tierCode, segmentEnabled } = currentTester || {};

      if (oldPublicId !== publicId && publicId && segmentEnabled) {
        Analytics.identify(publicId, email, tierCode);
        trackPage(true);
      }

      if (publicId && segmentEnabled) {
        resolve();
      } else {
        reject();
      }
    });

  const isTrackablePath = () =>
    config.trackedPagesPaths.find(route => {
      try {
        return matchPath(pathname, route) !== null;
      } catch (err) {
        return false;
      }
    });

  const shouldTrackPage = initialTesterLoad => {
    const pathChanged = previousPathname.current !== pathname;
    const shouldTrackPage = testerIdentified && pathChanged;

    return isTrackablePath() && (initialTesterLoad || shouldTrackPage);
  };

  const trackPage = (initialTesterLoad = false) => {
    if (shouldTrackPage(initialTesterLoad)) {
      Analytics.page();
    }
  };

  return (
    <>
      <div className="fixed z-10 bottom-0 lg:sticky lg:top-0 lg:z-20">
        <CookieConsent />
      </div>
      {children}
    </>
  );
};

AnalyticsTracker.propTypes = {
  children: PropTypes.node,
};

export default track(
  {},
  {
    dispatch: data => {
      Analytics.addEvent(data);

      if (testerIdentified) {
        Analytics.processEvents();
      }
    },
  },
)(AnalyticsTracker);
