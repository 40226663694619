import graphql from "graphql-tag";

export default graphql`
  query {
    error @client {
      message
      code
    }
  }
`;
