import mapKeys from "lodash/mapKeys";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";

import { SnakeCasedProperties, CamelCasedProperties } from "types/utility";

export const camelCaseKeys = <T extends Record<string, unknown>>(object: T) =>
  mapKeys<T>(object, (_, key) => camelCase(key)) as CamelCasedProperties<T>;

export const snakeCaseKeys = <T extends Record<string, unknown>>(object: T) =>
  mapKeys<T>(object, (_, key) => snakeCase(key)) as SnakeCasedProperties<T>;

// @ts-ignore
export function catchStorageError(callback) {
  try {
    callback();
  } catch (error) {
    // If we get error that we exceeded storage's quota
    // but storage is still empty we are in private mode
    // @ts-ignore
    if (error.code === DOMException.QUOTA_EXCEEDED_ERR && localStorage.length === 0) {
      // Private mode
    } else {
      throw error;
    }
  }
}
