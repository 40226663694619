import { addMethod, string } from "yup";

const ALLOWED_CHARACTERS_REGEX = /^(?!.*?(\/\/|[<>"$%={}~«»])).*$/i;

addMethod(string, "allowedCharacters", function (errorMessage) {
  return this.matches(
    ALLOWED_CHARACTERS_REGEX,
    errorMessage || 'Cannot include special characters (< > " $ % = { } ~ « » //).',
  );
});
