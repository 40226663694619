import { getEnv } from "app/env";

const TESTER_ACCOUNT_NAMESPACE = "/tester-account";
const TRACKER_ROOT = getEnv("TRACKER_ROOT");

export const websiteContentPaths = {
  root: "/",
  testerAccountRoot: "/tester-account",
  accountCreated: TESTER_ACCOUNT_NAMESPACE + "/account-created/:email",

  dashboard: TESTER_ACCOUNT_NAMESPACE + "/profile/dashboard",

  testerProfile: TESTER_ACCOUNT_NAMESPACE + "/profile",
  testerWorkHistory: TESTER_ACCOUNT_NAMESPACE + "/work-history",
  testerRegistration: TESTER_ACCOUNT_NAMESPACE + "/registration",
  testerPayouts: TESTER_ACCOUNT_NAMESPACE + "/payouts",
  testerIssues: TESTER_ACCOUNT_NAMESPACE + "/issues",
  testerAttachments: TESTER_ACCOUNT_NAMESPACE + "/attachments",
  testerPerformance: TESTER_ACCOUNT_NAMESPACE + "/performance",
  testerTestCycles: TESTER_ACCOUNT_NAMESPACE + "/test-cycles",
  testerReproducibilityTests: TESTER_ACCOUNT_NAMESPACE + "/reproducibility-tests",
  exploratoryTest: TESTER_ACCOUNT_NAMESPACE + "/exploratory-tests/:graphql_id",
  testerVerification: TESTER_ACCOUNT_NAMESPACE + "/verification",
  consent: TESTER_ACCOUNT_NAMESPACE + "/consent",
  error: TESTER_ACCOUNT_NAMESPACE + "/error",
  tracker_sso: TESTER_ACCOUNT_NAMESPACE + "/tracker-sso",
  flexibleTestingRoot: "/tests",
  joinTest: "/tests/:id/join",
  reproducibilityTestsRoot: "/reproducibility-tests",
  upwork: "/upwork",
  surveys: TESTER_ACCOUNT_NAMESPACE + "/surveys/:id",
  surveyCompleted: TESTER_ACCOUNT_NAMESPACE + "/surveys/:id/thank-you",
  testCaseInvitations: `/test_invitations`,
};

const testerAccountRoot = websiteContentPaths.testerAccountRoot;

export const testerAccountPaths = {
  root: testerAccountRoot,

  passwordReset: testerAccountRoot + "/password-reset",
  passwordResetSent: testerAccountRoot + "/password-reset-sent",
  requestPasswordReset: testerAccountRoot + "/request-password-reset",

  confirmRegistration: testerAccountRoot + "/confirm-registration/:token",
  accountCreated: testerAccountRoot + "/account-created/:email",

  signUp: testerAccountRoot + "/sign-up",
  signIn: testerAccountRoot + "/sign-in",
  signOut: testerAccountRoot + "/sign-out",

  attachments: testerAccountRoot + "/attachments",

  payouts: testerAccountRoot + "/payouts",
  invitation: testerAccountRoot + "/test-cycle-invitation/:token",
  exploratoryTests: testerAccountRoot + "/exploratory-tests",

  workHistory: testerAccountRoot + "/work-history",

  dashboard: testerAccountRoot + "/profile/dashboard",

  registrationRoot: testerAccountRoot + "/registration",
  registrationAccountSettings: testerAccountRoot + "/registration/account-settings",
  unconfirmedAccount: testerAccountRoot + "/registration/unconfirmed-account",
  registrationTestingSettings: testerAccountRoot + "/registration/testing-settings",
  registrationHardwareAndSoftware: testerAccountRoot + "/registration/hardware-software",
  registrationCommunityTest: testerAccountRoot + "/registration/community-test",
  registrationFollowUpTest: testerAccountRoot + "/registration/follow-up-test",

  profileSettings: testerAccountRoot + "/profile/profile-settings",
  profileTestingSettings: testerAccountRoot + "/profile/testing-settings",
  profileHardwareAndSoftware: testerAccountRoot + "/profile/hardware-software",
  accountTestingSettings: testerAccountRoot + "/profile/account-settings",
  profileAbsenceCalendar: testerAccountRoot + "/profile/absence-calendar",
  profilePreferences: testerAccountRoot + "/profile/preferences",

  checkUpVerification: testerAccountRoot + "/verification",
  consent: testerAccountRoot + "/consent",
  testCycles: testerAccountRoot + "/test-cycles",
  performance: testerAccountRoot + "/performance",
  reproducibilityTests: testerAccountRoot + "/reproducibility-tests",
};

export const trackerPaths = {
  signIn: TRACKER_ROOT + "/users/sign_in",
  signOut: TRACKER_ROOT + "/users/sign_out",
};

export const defaultPagePath = testerAccountPaths.testCycles;
