import { RootQuery, useGetFeatureFlagsQuery } from "generated/graphql";

const toMinutesMsMultiplier = 1000 * 60;
const pollInterval = 10 * toMinutesMsMultiplier;

class FeatureFlags {
  pollInterval: number = pollInterval;
  initialized = false;
  enabledFeatureFlags: RootQuery["featureFlags"] = [];

  variation<T>(name: string, defaultValue: T | undefined, callback: (result: T) => void) {
    if (!this.initialized) {
      return defaultValue;
    }

    const result = this.enabledFeatureFlags.indexOf(name) > -1;

    callback && callback(result as T);
  }

  setEnabled(featureFlags: RootQuery["featureFlags"]) {
    this.initialized = true;
    this.enabledFeatureFlags = featureFlags;
  }

  shutdown() {
    this.initialized = false;
    this.enabledFeatureFlags = [];
  }
}

export function useFeatureFlag(name: string) {
  const { data } = useGetFeatureFlagsQuery({ fetchPolicy: "cache-only" });

  return data?.featureFlags?.includes(name) || false;
}

export default new FeatureFlags();
