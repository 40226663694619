import authenticationActionTypes from "../action_types/tester_account/authentication_action_types.js";

const { SIGN_OUT } = authenticationActionTypes;

const createLoginMiddleware = onSignOut => store => next => action => {
  if (action.type === SIGN_OUT) {
    onSignOut();
  }

  return next(action);
};

export default createLoginMiddleware;
