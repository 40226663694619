function authenticatedConfig(config, state) {
  const {
    organization: { authenticationToken: token },
  } = state;

  if (token) {
    config = Object.assign(config, {
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    });
  } else {
    throw new Error("No token!");
  }

  return config;
}

function jsonConfig(config) {
  delete config.json;
  config.headers = {
    ...config.headers,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  config.body = JSON.stringify(config.body);

  return config;
}

function callApi(url, config, authenticated, getState) {
  if (authenticated) {
    config = authenticatedConfig(config, getState());
  }

  if (config.json) {
    config = jsonConfig(config);
  }

  return fetch(url, config)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (response.ok) {
        return json;
      } else {
        return Promise.reject(json);
      }
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

const CALL_API = Symbol("Call API");

const normalizeAction = actionObject => {
  const action = actionObject.action || actionObject;

  if (actionObject.action) {
    delete actionObject.action;
  }

  return {
    after: () => ({}),
    type: action.toString(),
    ...actionObject,
  };
};

export default ({ getState }) =>
  next =>
  action => {
    const callAPI = action[CALL_API];

    // So the middleware doesn"t get applied to every single action
    if (typeof callAPI === "undefined") {
      return next(action);
    }

    const { url, actions, authenticated, ...fetchOptions } = callAPI;

    const actionWith = data => {
      const finalAction = Object.assign({}, action, data);

      delete finalAction[CALL_API];

      return finalAction;
    };

    const baseAction = normalizeAction(actions[1] || actions[0]);
    const requestAction = baseAction && normalizeAction(actions[0]);
    const errorAction = normalizeAction(actions[3] || baseAction);

    if (requestAction) {
      next(actionWith({ type: requestAction.type }));
    }

    return callApi(url, fetchOptions, authenticated, getState).then(
      response => {
        const successAction = actionWith({
          response,
          type: baseAction.type,
        });

        next(successAction);

        baseAction.after(successAction, getState);
      },
      // TODO: check if this works with all returned api errors,
      // maybe some kind of error_render in API to simplify things?
      error => {
        const errorActionWithResponse = actionWith({
          type: errorAction.type,
          response: error,
          error: true,
        });

        next(errorActionWithResponse);

        errorAction.after(errorActionWithResponse, getState);
      },
    );
  };
