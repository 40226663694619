const initialState = {
  alerts: {},
  testerAccount: {
    loginTokenChecked: false,
    autoSignedIn: false,
    authenticationToken: "",
    passwordResetFormErrors: {},
    notifications: {
      setup: "unknown",
      displayNextPromptAfter: null,
    },
  },
};

export default initialState;
