import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { onError } from "apollo-link-error";

import { testerApiPaths } from "configuration/api_paths";
import apolloLink from "../sentry/apollo_link";
import GET_ERROR from "components/graphql_errors/get_error";
import { getEnv } from "app/env";

let token = null;

export const setToken = newToken => {
  token = newToken;
};

const extensionCodeToErrorCode = {
  NOT_FOUND: 404,
  UNAUTHENTICATED: 401,
  FORBIDDEN: 403,
  UNAUTHORIZED: 403,
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { cache, suppressErrorLink } = operation.getContext();

  if (suppressErrorLink) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (getEnv("APP_ENV") === "development") {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`, locations);
      }

      if (extensions && extensions.code) {
        cache.writeQuery({
          query: GET_ERROR,
          data: {
            error: {
              __typename: "error",
              message,
              code: extensionCodeToErrorCode[extensions.code] || extensions.code,
            },
          },
        });
      }
    });
  }

  if (networkError) {
    cache.writeQuery({
      query: GET_ERROR,
      data: {
        error: {
          __typename: "error",
          message: "",
          code: networkError.statusCode || "NETWORK_ERROR",
        },
      },
    });

    console.error(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({ uri: testerApiPaths.graphql });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: token && `Bearer ${token}`,
    },
  });

  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    // Relay Connection types don't have identifying keys, so Apollo cache will always overwrite the cache with
    // whatever fields it will receive from a query or mutation result. This is safe to tell the cache to treat
    // the CaseConnection type as the same object in the context of identifiable object (in most cases Test) unless
    // it will be used for any root query field (which shouldn't happen).
    // Read more: https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
    // IMPROVEMENT IDEA: Autogenerate type policies for all Connection type which should have `merge: true` based on
    // autogenerated types (exclude Connection types used in root query)
    CaseConnection: {
      merge: true,
    },
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([apolloLink, errorLink, authMiddleware, httpLink]),
  cache,
});
