import { createActionTypes } from "../helpers";

const types = createActionTypes("testerAccount/authentication", [
  "SIGN_IN",
  "AUTO_SIGN_IN",
  "SIGN_OUT",
  "LOGIN_TOKEN_CHECKED",
]);

export default types;
