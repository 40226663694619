import React, { useState } from "react";
import PropTypes from "prop-types";

import ErrorModal from "./error_modal";

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const InternetExplorerCheck = ({ children }) => {
  const [isOpen, setIsOpen] = useState(isIE);

  return (
    <>
      <ErrorModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      {children}
    </>
  );
};

InternetExplorerCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InternetExplorerCheck;
